<template>
  <div class="Reports__View">
    <EntityListLayout v-if="tabs.length" :tabs="tabs" ref="entity" @onNewEntitySave="onNewEntitySave">
      <template #title>חוקים</template>
      <template #button>
        <a @click="addLaw" class="link-button">
          חוק חדש
        </a>
      </template>
      <template v-slot:counter="slotProps">
        סה"כ <b>{{ getTotalRecords(slotProps) }} </b>
      </template>
    </EntityListLayout>
  </div>
</template>
<script>
import AdminService from "@/services/AdminService";
import EntityListLayout from "@/components/EntityListLayout.vue";
import DialogEditLaw from '@/components/DialogEditLaw.vue';
import {
  ModalProgrammatic as Modal,
} from 'buefy';
import FieldService from "@/services/FieldService";
export default {
  name: "Reports",
  data() {
    return {
      tabs: [],
    };
  },
  components: {
    EntityListLayout,
  },
  computed: {
  },
  created() {
    const password = prompt('סיסמה');
    if (password !== 'nevoLaw') this.$router.push('admin');
    this.create();
  },
  methods: {
    create() {
      const lessonsFields = FieldService.list("verdicts");
      const fields = [
        { ...lessonsFields.Id, checked: true },
        { ...lessonsFields.Name, checked: true },
      ];

      this.tabs = [
        {
          id: "AllLawsCollection",
          isPrimary: true,
          title: "כל החוקים",
          getDataAsync: this.getAllLaws,
          perPage: 100,
          fields,
          filters: null,
          sortField: "Id",
          sortOrder: "desc",
          checkableRows: false,
          fastSearch: this.getFilteredLaws,
          fastSearchOptions: {
            propName: "Terms",
            label: "חיפוש מהיר",
          },
          sideViewModal: DialogEditLaw,
        },
      ];
    },
    addLaw() {
      Modal.open({
        component: DialogEditLaw,
        props: {
          store: this.$store,
          isNew: true
        },
        canCancel: false,
        hasModalCard: true,
        trapFocus: true,
      });
    },
    onNewEntitySave() {
      this.$refs.entity.onNewEntityCancel();
    },
    getAllLaws() {
      return AdminService.getLaws().then((r) => ({
        data: {
          TotalResults: r.data.length,
          Items: r.data,
        },
      }));
    },
    getFilteredLaws(query) {
      const { data, term } = query;
      return data
        .filter((i) => {
          const values = Object.values(i);
          return values.some((v) => (v
            && typeof v !== 'boolean'
            && typeof v !== 'number' ? !term || v.indexOf(term) > -1 : v === parseInt(term, 10)));
        });
    },
    getTotalRecords(slotProps) {
      return slotProps.tab && slotProps.tab.total && slotProps.tab.total;
    },
  },
};
</script>
<style lang="scss">
.Reports__View {
  height: 100%;
}

.link-button {
  background: #00427e;
  border: none;
  border-radius: 3px;
  padding: 14px 25px;
  color: white;
  cursor: pointer;
}

// .not-activated-row {
//   background-color: #fff !important;
// }
</style>
